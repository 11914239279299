import React, { ReactElement } from "react"
import ApplicationForm from "../application-form"
import Data from "../../data/home"
import { navigate } from "gatsby"
import Button from "../../components/button"
import EMICalculator from "../../hoc/emi-calc"

function HomeContainer({ }: any): ReactElement {
  const transitionTo = (entity: any) => {
    navigate("/apply")
  }

  const {
    heading,
    homeFeatures,
    moreFeatures,
    iconBullets,
    moreFeatures2,
    descriptions,
    formSchema,
  } = Data
  return (
    <>
      <div
        className="min-h-screen w-screen bg-right    bg-cover md:bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(${require("../../images/illustration.svg").default})`,
        }}
      >
        <div className="pt-32 md:pt-64">
          <div className="flex flex-wrap w-full md:w-3/4 mx-auto">
            <div className="w-full md:w-2/4 p-4">
              <ApplicationForm schema={formSchema} onApply={transitionTo} />
            </div>
            <div className="w-full md:w-2/4 p-4 text-center text-blue-900 text-3xl">
              {heading}
            </div>
          </div>
        </div>

      </div>

      <div className="hidden bg-blue-900 p-4 text-gray-100  bottom-0 w-full md:flex flex-row justify-center ">
        {homeFeatures.map((hf: string, index: number) => (
          <div key={index} className="flex flex-row items-center m-2">
            <div className="flex-shrink-0 pr-4">
              <img src={require("../../images/checkmark.svg").default} />
            </div>
            <div className="text-gray-200">{hf}</div>
          </div>
        ))}
      </div>

      <div
        style={{
          backgroundImage: `url(${require("../../images/clouds-bg.svg").default})`,
        }}
        className="min-h-screen bg-no-repeat bg-cover flex flex-wrap w-full items-center  mx-auto p-8"
      >

        <div className="py-8 w-full md:w-3/4 mx-auto">
          <div className="text-3xl text-center ">{moreFeatures.title}</div>
          <div className="text-md text-center mb-4">
            {moreFeatures.subTitle}
          </div>

          <div className="bg-blue-200 my-8 p-8 rounded-lg text-gray-600 text-sm">{moreFeatures.notice}</div>
          
          <div className="bg-gray-100 rounded-lg py-8 shadow-md">
            <div className="text-lg text-center py-4">
              {moreFeatures.itemsTitle}
            </div>
            <div className="w-full px-8 flex flex-row flex-wrap justify-between mt-2 mx-auto">
              {moreFeatures.items.map((mf: string, index: number) => (
                <div
                  key={index}
                  className="w-full md:w-1/2 flex items-center p-2 my-2"
                >
                  <img
                    src={require("../../images/checkmark.svg").default}
                    className="mr-4"
                  />
                  <span className="text-gray-500">{mf}</span>
                </div>
              ))}
            </div>
            <div className=" bg-white my-8 p-8 rounded-lg  shadow-md mx-auto"
            style={{ maxWidth: "800px" }}>
            <EMICalculator />
          </div>
            <div className="my-8 text-center ">
              <div className="text-white">
                <Button
                  type="secondary"
                  className="shadow-xl"
                  onClick={transitionTo}
                >
                  {moreFeatures.btnText}
                </Button>
              </div>
              <div className="text-sm text-gray-400 mt-4">
                {moreFeatures.tncText}
              </div>
            </div>
          </div>
        </div>

        <div className="my-16 w-full md:w-3/4 mx-auto flex-wrap flex flex-row justify-evenly">
          {iconBullets.map((ib: any, index: number) => (
            <div key={index} className="p-2 flex flex-col justify-center">
              <img src={ib.image} />
              <strong className="p-2">{ib.title}</strong>
            </div>
          ))}
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${require("../../images/clouds-bg.svg").default})`,
        }}
        className=" pb-32 bg-no-repeat bg-cover flex flex-wrap w-full items-stretch  mx-auto p-8"
      >
        <div
          className="w-full h-full md:w-3/4 mx-auto text-white  relative"
        // style={{ minHeight: "400px" }}
        >
          <div
            className="z-10 w-full h-full  px-16 py-16 "
            style={{ zIndex: 1 }}
          >
            <div className="text-3xl py-4">{moreFeatures2.title}</div>
            <div className="flex flex-col mt-4 text-gray-300">
              {moreFeatures2.points.map((p: string, index: number) => (
                <div key={index} className="py-4 flex flex-row items-start">
                  <img
                    src={require("../../images/checkmark.svg").default}
                    className="mr-4"
                  />
                  {p}
                </div>
              ))}
            </div>
            <div className="flex justify-end mt-8">
              <Button
                onClick={transitionTo}
                className="shadow-lg"
                type="secondary"
              >
                Jetzt beantragen
              </Button>
            </div>
            <div className="flex flex-row justify-evenly">
              <div className="">{/* <img src={} /> */}</div>
            </div>
          </div>
          <div
            className="top-0 bottom-0 absolute w-full h-full  rounded-md shadow-xl"
            style={{
              zIndex: -1,
              transform: ` skewY(5deg)`,
              backgroundImage: "linear-gradient(#7BAFAD,#497BA7)",
            }}
          />
        </div>
      </div>
      <div
        style={{ minHeight: "400px" }}
        className="bg-blue-400 bg-no-repeat bg-cover flex flex-wrap w-full items-stretch  mx-auto p-8"
      >
        <div className="w-full h-full md:w-2/4 mx-auto text-blue-200 py-8 relative">
          {descriptions.map((desc: any, index: number) => (
            <div key={index}>
              <div className="text-3xl text-blue-100  my-8">{desc.title}</div>
              <div
                className=" text-justify"
                dangerouslySetInnerHTML={{ __html: desc.description }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default HomeContainer
