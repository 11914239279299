export default {
  heading: "Der einfachste Weg, um einen Kredit zu bekommen!",
  homeFeatures: [
    "Genehmigung ohne Schufa-Prüfung",
    "Unkomplizierte Auszahlung",
    "Diskrete Abwicklung durch Erfahrung",
  ],
  moreFeatures: {
    title: "Der Sofortkredit - sofort wunschlos glücklich.",
    subTitle: "Schnell verfügbar und frei verwendbar.",
    notice: `Mit einem Sofortkredit kommen Sie schnell und unkompliziert an Bargeld. Es entfallen die meist zeitraubenden und unüberschaubaren Formalitäten. Die Sofort24Kredit kann hier zu überzeugenden Konditionen vermitteln. Sofortkredite werden in der regel innerhalb kürzester Zeit unbürokratisch ausgezahlt. Komplizierte Gespräche mit Banken und deren Beratern entfallen hier.`,
    itemsTitle: "Ein Sofortkredit bietet Ihnen folgende Vorteile:",
    items: [
      "Angebotserstellung ist für Sie 100% kostenlos & unverbindlich (Wie es der Rechtslage entspricht)",
      "Die Antragsprüfung und Entscheidung erfolgt sofort online!",
      "Zeitraubende Bankbesuche entfallen",
      "Sofortige Antragsannahme ist garantiert!",
      "Schnell, diskret und einfach",
      "Betrag meistens innerhalb von 24 Stunden verfügbar.",
      "Machen Sie mit Ihrem Geld was Sie wollen! (Keine Zweckbindung der Kredite)",
      "Unser freundlicher Kundenservice berät Sie professionell bei allen Anliegen",
      "Laufzeiten flexibel wählbar",
      "Individuell nach Ihrem Wunsch: Zinssatz fest oder flexibel während der Laufzeit",
      "Ohne aufwändige Vertreterbesuche, alles wird auf dem Postweg erledigt",
    ],
    tncText: "*kostenlos & unverbindlich (Wie es der Rechtslage entspricht)",
    btnText: "Jetzt Antrag erstellen*",
  },
  iconBullets: [
    {
      title: "Geld auf Ihr Konto",
      image: require("../images/user-icon.svg").default,
    },
    {
      title: "100% Datensicherheit",
      image: require("../images/lock-icon.svg").default,
    },
    {
      title: "Kostenlos & unverbindlich",
      image: require("../images/check-icon.svg").default,
    },
    {
      title: "Schnelle Auszahlung",
      image: require("../images/time-icon.svg").default,
    },
  ],
  moreFeatures2: {
    title: "Personalisierte Kredite für jeden Zweck",
    points: [
      "Wir machen den Vertragsabschluss so bequem wie möglich: komplett online oder mit Postident oder in einer Partnerbank.",
      "Berechnen Sie Ihre Rate und erhalten Sie direkt die Entscheidung über Ihren individuellen Kreditbetrag.",
      "Schnell und zuverlässig - schon 1 bis 3 Werktage nach Eingang Ihrer Vertrags­unterlagen erhalten Sie Ihr Geld.",
    ],
  },
  descriptions: [
    {
      title: "Der Kredit, der mehr kann.",
      description:
        "Wir finden gemeinsam mit Ihnen immer eine gute Lösung. Ob Raten reduzieren, aussetzen, den Kredit vorzeitig zurückzahlen oder eine Sondertilgung vornehmen – bei uns selbstverständlich.<br/><br/> Zu Ihrem Earnstfall Finanz können Sie eine clevere Erweiterung dazu buchen: die Earnstfall Finanz-Finanzreserve. Erhalten Sie je nach Bonität bis zu 50.000 Euro zusätzlich zu Ihrem Kredit. Ihr Mehr an Flexibilität: Sie können Ihren laufenden Kredit jederzeit ohne neuen Kreditantrag erweitern.<br/><br/> Ob online, am Telefon oder in einer von über 10.000 Partnerbank-Filialen. Wir sind immer und überall für Sie da, wenn Sie uns brauchen.",
    },
    {
      title: "Auswirkungen der Nichtzahlung",
      description:
        "Wenn Sie nicht rechtzeitig zahlen können, hat jeder Kreditgeber seine eigenen Richtlinien in Bezug auf Gebühren und Zinsen und wie sie ausstehende Schulden einziehen. Einige Kreditgeber verfolgen Sie bei wiederholtem Zahlungsverzug mit rechtlichen Mitteln. Die meisten werden Sie per Telefon oder Brief kontaktieren, um die Zahlung neu zu arrangieren. Nichtzahlung kann zu Gebühren und / oder erhöhten Zinsen führen und Ihre Kreditwürdigkeit beeinträchtigen. Bitte lesen Sie den Abschnitt Gebühren auf der Website des Kreditgebers, bevor Sie Ihr Darlehen abschließen.",
    },
    {
      title: "Sammelpraktiken",
      description:
        "Wenn Sie nicht rechtzeitig zahlen können, hat jeder Kreditgeber seine eigenen Richtlinien in Bezug auf Gebühren und Zinsen und wie sie ausstehende Schulden einziehen. Einige Kreditgeber verfolgen Sie bei wiederholtem Zahlungsverzug mit rechtlichen Mitteln. Die meisten werden Sie per Telefon oder Brief kontaktieren, um die Zahlung neu zu arrangieren. Nichtzahlung kann zu Gebühren und / oder erhöhten Zinsen führen und Ihre Kreditwürdigkeit beeinträchtigen. Bitte lesen Sie den Abschnitt Gebühren auf der Website des Kreditgebers, bevor Sie Ihr Darlehen abschließen.",
    },
    {
      title: "Überschläge",
      description:
        "Wenn Sie nicht rechtzeitig zahlen können, hat jeder Kreditgeber seine eigenen Richtlinien in Bezug auf Gebühren und Zinsen und wie sie ausstehende Schulden einziehen. Einige Kreditgeber verfolgen Sie bei wiederholtem Zahlungsverzug mit rechtlichen Mitteln. Die meisten werden Sie per Telefon oder Brief kontaktieren, um die Zahlung neu zu arrangieren. Nichtzahlung kann zu Gebühren und / oder erhöhten Zinsen führen und Ihre Kreditwürdigkeit beeinträchtigen. Bitte lesen Sie den Abschnitt Gebühren auf der Website des Kreditgebers, bevor Sie Ihr Darlehen abschließen.",
    },
	{
      title: "REPRÄSENTATIVEN BEISPIEL",
      description:
        "*Angaben gem. § 6a PAngV: Unveränderlicher Sollzinssatz ab 3,92%, effektiver Jahreszins ab 3,99% - 15,99%, Nettodarlehensbetrag ab 1.000,- bis 50.000,- €, Laufzeit von 12 bis 120 Monaten, Bonität vorausgesetzt. Bei einem Nettodarlehensbetrag von 10.000,- € und einer Laufzeit von 36 Monaten erhalten 2/3 der Kunden von Sofort24Kredit vorraussichttlich einen eff. Zinssatz von 8,90% oder günstiger (geb. Sollzinssatz 5,26%).",
    },
	
    {
      title: "Kontaktiere uns",
      description:
        "E-mail : info@sofort24kredit.de",
    },
  ],
  formSchema: [
    {
      label: "Gewünschter Betrag",
      prefix: "€",
      type: "range",
      min: 1000,
      max: 50000,
      step: 1000,
      name: "amount",
      defaultValue: 1000,
    },
    {
      label: "Laufzeit",
      suffix: "Monate",
      type: "select",
      options: [
        { value: 3, title: "3" },
        { value: 6, title: "6" },
        { value: 12, title: "12" },
        { value: 18, title: "18" },
        { value: 24, title: "24" },
        { value: 36, title: "36" },
      ],
      name: "months",
      valueVisible: true,
      defaultValue: 3,
      spread: true,
    },
  ],
}
