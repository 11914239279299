import React, { ReactElement } from "react"
import DefaultLayout from "../layouts/default"
import HomeContainer from "../hoc/home"
import HelmetContainer from "../hoc/helmet"

function Index({}: any): ReactElement {
  return (
    <>
      <DefaultLayout>
        <HelmetContainer pageTitle="Startseite" />
        <HomeContainer />
      </DefaultLayout>
    </>
  )
}

export default Index
